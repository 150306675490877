*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    font-family: "Calibre", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 22px;
    line-height: 1.42;
    font-weight: 400;
    quotes: "«" "»";
}

body {
    background-color: var(--color-white);
    color: var(--color-black);
    margin: 0;
}

h1 {
    font-size: 2.4rem;
    line-height: 1;
    margin: 1rem 0;
}

h2 {
    font-size: 1.4rem;
    line-height: 1.2;
    margin: 2.4rem 0 1.2rem;
}

h3 {
    font-size: 1.2rem;
    line-height: 1;
    margin: 2rem 0 0.25rem;
}

p {
    margin: 0 0 30px;
}

address {
    font-style: normal;
}

a {
    color: inherit;
    transition: all 0.1s linear;
}

a:hover {
    text-decoration: none;
}

a:focus {
    background-color: var(--color-yellow);
}

a:active {
    opacity: 0.5;
}

ul, ol {
    padding-left: 1rem;
}

select {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    background: transparent;
    color: inherit;
    border: 2px solid var(--color-turquoise);
    border-radius: 0;
    padding: 3px 10px 5px;
}

select:focus {
    border-color: var(--color-black);
}

button {
    font-family: inherit;
    font-size: 1rem;
    cursor: pointer;
}

@media (--medium-down) {
    h1 {
        font-size: 2rem;
        margin-bottom: 0.5rem;
    }

    h2 {
        margin-bottom: 0.5rem;
    }
}

@media (--small-down) {
    html {
        font-size: 18px;
    }
}
