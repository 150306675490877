/**
 * @title Professionals list
 * @id components/professionals-list
 * @markup ../../styleguide/professionals-list.html
 * @width 7
 */
.professionals-list {
    margin: 0;
    padding: 0;
}

.professionals-list__item {
    list-style: none;
    margin: 40px 0;
}
