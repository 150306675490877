/**
 * @title Menu toggle
 * @id components/menu-toggle
 * @markup ../../styleguide/menu-toggle.html
 * @width 12
 */
.menu-toggle {
    border: none;
    padding: 10px 20px 14px;
    background: var(--color-yellow);
    border-radius: 0 0 4px 4px;
}

.menu-toggle:focus {
    background: var(--color-turquoise);
}

@media (--small-down) {
    .menu-toggle {
        padding: 6px var(--gutter);
        text-align: right;
        width: 100%;
    }
}
