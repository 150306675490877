/**
 * @title Page list
 * @id components/page-list
 * @markup ../../styleguide/page-list.html
 * @width 8
 */
.page-list {
    margin: 0 0 4rem;
    padding: 0;
}

.page-list__item {
    list-style: none;
    position: relative;
    padding-left: 1.5rem;
    margin: 0 0 2rem;
}

.page-list__item::before {
    content: "";
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: var(--color-yellow);
    position: absolute;
    top: 0.3rem;
    left: 0;
}

.page-list__link {
    font-size: 1.2rem;
    line-height: 1;
}

.page-list__lead {
    margin-top: 0.5rem
}
