/**
 * @title Product list
 * @id components/product-list
 * @markup ../../styleguide/product-list.html
 * @width 12
 */
.product-list {
    margin: 2rem -var(--half-gutter) 3rem;
    padding: 0;
    font-size: 0;
}

.product-list__item {
    font-size: 1rem;
    display: inline-block;
    width: calc(100% / 12 * 4);
    vertical-align: top;
    padding: 0 var(--half-gutter);
    margin-bottom: 2rem;
}

.product-list__item[hidden] {
    display: none;
}

.product-list__figure {
    margin: 0;
}

.product-list__image {
    max-width: 100%;
}

.product-list__heading {
    display: inline;
    margin: 0;
}

.product-list__price {
    display: inline;
    margin: 0;
    font-size: 1.4rem;
    font-weight: bold;
}

.product-list__summary {
    margin: 0;
}

@media (--medium-down) {
    .product-list__item {
        width: calc(100% / 12 * 6);
    }
}

@media (--small-down) {
    .product-list__item {
        width: calc(100% / 12 * 12);
    }
}
