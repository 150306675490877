/**
 * @title Thank you message
 * @id components/thank-you-message
 * @markup ../../styleguide/thank-you-message.html
 * @width 12
 */
.thank-you-message {
    border: 1px solid var(--color-black);
    background-image: inline("images/heart.svg");
    background-repeat: no-repeat;
    background-position: var(--breakout) 1.3rem;
    background-size: 30px 27px;
    padding: 1rem 1rem 1px calc(var(--breakout) + 40px);
    margin: 3rem 0;
}

.thank-you-message__heading {
    margin-top: 0;
}

.thank-you-message__text {
    max-width: 20em;
}

@media (--xlarge-down) {
    .thank-you-message {
        background-position: var(--half-gutter) 1.3rem;
        padding-left: calc(var(--half-gutter) + 40px);
    }
}
