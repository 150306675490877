/**
 * @title Event teaser
 * @id components/event-teaser
 * @markup ../../styleguide/event-teaser.html
 * @width 12
 */

/**
 * @title with h3 (instead of h2)
 * @id components/event-teaser/h3
 * @markup ../../styleguide/event-teaser--h3.html
 * @width 12
 */
.event-teaser {
    border: 1px solid var(--color-black);
    margin: 1rem 0;
    display: flex;
    flex-direction: row-reverse;
}

.event-teaser[hidden] {
    display: none;
}

.event-teaser__main {
    width: 75%;
    padding: 1rem 25% 1rem calc(var(--breakout) - 1px);
    border-left: 1px solid var(--color-black);
}

.event-teaser__heading,
.event-teaser__lead {
    margin: 0 0 0.5rem;
}

.event-teaser__figure {
    max-width: 405px;
    margin: 0 0 2rem;
}

.event-teaser__image {
    width: 100%;
    vertical-align: top;
}

.event-teaser__data {
    width: 25%;
    padding: 1rem calc(var(--breakout) - 1px);
    background: var(--color-turquoise);
}

.event-teaser__date,
.event-teaser__time,
.event-teaser__location,
.event-teaser__area {
    margin: 0;
}

@media (--xlarge-down) {
    .event-teaser__main,
    .event-teaser__data {
        padding-left: var(--half-gutter);
        padding-right: var(--half-gutter);
    }
}

@media (--medium-down) {
    .event-teaser {
        flex-direction: column-reverse;
    }

    .event-teaser__main {
        width: 100%;
        border-left: none;
        border-top: 1px solid var(--color-black);
    }

    .event-teaser__data {
        width: 100%;
        padding-right: 25%;
    }
}

/**
 * @title expanded
 * @id components/event-teaser/expanded
 * @markup ../../styleguide/event-teaser--expanded.html
 * @width 12
 */
.event-teaser--expanded {
    margin-bottom: 0;
}

.event-teaser--expanded .event-teaser__lead {
    font-size: 1.2rem;
    line-height: 1.3;
    margin: 3rem 0 2rem;
}
