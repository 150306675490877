/**
 * @title Page teasers
 * @id components/page-teasers
 * @markup ../../styleguide/page-teasers.html
 * @width 12
 */
.page-teasers {
    font-size: 0;
}

.page-teasers__teaser {
    font-size: 1rem;
    width: calc(100% / 12 * 4);
    display: inline-block;
    vertical-align: top;
    padding-right: calc(2 * var(--gutter));
    margin-bottom: 3rem;
}

.page-teasers__teaser[hidden] {
    display: none;
}

.page-teasers__figure {
    margin: 0 0 1rem;
}

.page-teasers__image {
    width: 100%;
}

.page-teasers__heading {
    margin: 0;
}

.page-teasers__published-date {
    font-size: 0.9rem;
    color: var(--color-gray);
    margin: 0;
}

.page-teasers__lead {
    margin: 0.5rem 0 0;
}

@media (--medium-down) {
    .page-teasers__teaser {
        width: calc(100% / 12 * 6);
    }
}

@media (--small-down) {
    .page-teasers__teaser {
        width: calc(100% / 12 * 12);
    }
}
