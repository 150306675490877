/**
 * @title Accordion
 * @id components/accordion
 * @markup ../../styleguide/accordion.html
 * @width 8
 */
.accordion {
    margin: 1rem 0;
    border: 2px solid var(--color-yellow);
}

.accordion__heading {
    margin: 0;
}

.accordion__button {
    display: block;
    width: 100%;
    background: transparent;
    border: none;
    text-align: left;
    padding: 0.5rem 1.8rem 0.5rem calc(var(--breakout) - 2px);
    font-weight: inherit;
    position: relative;
}

.accordion__button::after {
    content: "";
    border-color: var(--color-turquoise) transparent transparent;
    border-style: solid;
    border-width: 0.6rem 0.3rem;
    position: absolute;
    top: 0.7rem;
    right: 0.6rem;
}

.accordion.is-expanded {
    border-color: var(--color-turquoise);
}

.accordion.is-expanded .accordion__button::after {
    border-color: transparent transparent var(--color-turquoise);
    margin-top: -0.6rem;
}

.accordion__body {
    margin: 0.5rem 30px 2rem var(--breakout);
}
