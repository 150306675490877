/**
 * @title Cart
 * @id components/cart
 * @markup ../../styleguide/cart.html
 * @width 12
 */
.cart {
    background: var(--color-lightturquoise);
    padding: 1rem var(--breakout) 0;
}

.cart__contents {
    border-collapse: collapse;
}

.cart__column-heading,
.cart__data-cell {
    text-align: left;
    padding: 0.5rem 0.5rem 0 0;
}

.cart__data-cell--count {
    width: 4rem;
}

.cart__total {
    font-weight: bold;
    margin: 1rem 0;
}

@media (--xlarge-down) {
    .cart {
        padding-left: var(--half-gutter);
        padding-right: var(--half-gutter);
    }
}
