/**
 * @title Checkbox
 * @id components/checkbox
 * @markup ../../styleguide/checkbox.html
 * @width 8
 */
.checkbox {
    font-weight: normal;
    position: relative;
    cursor: pointer;
}

.checkbox__box {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
}

.checkbox__text::before {
    content: "";
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: -0.2rem;
    margin-right: 0.5rem;
    border: 2px solid var(--color-turquoise);
    padding: 0.2rem;
    background-clip: content-box;
}

.checkbox__box:checked + .checkbox__text::before {
    background-color: var(--color-black);
}

.checkbox__box:hover + .checkbox__text::before,
.checkbox__box:focus + .checkbox__text::before {
    border-color: var(--color-black);
}
