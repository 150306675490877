/**
 * @title Unordered list
 * @id components/ul
 * @markup ../../styleguide/ul.html
 * @width 8
 */
.ul > li {
    list-style: none;
    position: relative;
}

.ul > li::before {
    content: "• ";
    color: var(--color-yellow);
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -1rem;
    font-size: 1.4rem;
    line-height: 1;
}
