/**
 * @title Video block
 * @id components/video-block
 * @markup ../../styleguide/video-block.html
 * @width 8
 */
.video-block {
    margin: 0 0 60px;
}

.video-block__wrapper {
    position: relative;
    padding-top: calc(9 / 16 * 100%);
}

.video-block__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.video-block__caption {
    color: var(--color-darkgray);
    font-style: italic;
    margin: 8px 10px 0;
}
