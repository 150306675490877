.l-article {
    margin: 0 -var(--half-gutter);
}

.l-article__header {
    padding: 0 var(--half-gutter);
}

.l-article__main::after {
    content: "";
    display: block;
    clear: right;
}

.l-article__block {
    width: calc(100% / 12 * 8);
    padding: 0 var(--half-gutter);
}

.l-article__breakout {
    margin-left: -var(--breakout);
    margin-right: -var(--breakout);
}

.l-article__aside {
    float: right;
    width: calc(100% / 12 * 4);
    padding: 0 var(--half-gutter);
    margin: 1rem 0;
    margin-right: -var(--breakout);
}

.l-article__fullwidth {
    width: calc(100% / 12 * 12);
    padding: 0 var(--half-gutter);
    clear: right;
}

@media (--xlarge-down) {
    .l-article__breakout {
        margin-left: -var(--half-gutter);
        margin-right: -var(--half-gutter);
    }

    .l-article__aside {
        margin-right: 0;
    }
}

@media (--medium-down) {
    .l-article__block {
        width: auto;
        clear: right;
    }

    /* This is for things that are fine with giving up space to floats, i.e.
     * generally things that are just text and don't have borders/background
     */
    .l-article__block--text {
        clear: none;
    }

    .l-article__aside {
        width: calc(100% / 12 * 6);
    }
}

@media (--small-down) {
    .l-article__aside {
        float: none;
        width: auto;
    }
}
