/**
 * @title Image block
 * @id components/image-block
 * @markup ../../styleguide/image-block.html
 * @width 8
 */
.image-block {
    margin: 0 0 60px;
}

.image-block__image {
    width: 100%;
}

.image-block__caption {
    color: var(--color-darkgray);
    font-style: italic;
}

/**
 * @title Small
 * @id components/image-block/small
 * @markup ../../styleguide/image-block--small.html
 * @width 4
 */
.image-block--small {
    margin-bottom: 0;
}

/**
 * @title Portrait
 * @id components/image-block/portrait
 * @markup ../../styleguide/image-block--portrait.html
 * @width 8
 */
.image-block--portrait::after {
    content: "";
    display: block;
    clear: left;
}

.image-block--portrait .image-block__image {
    float: left;
    width: 50%;
    width: calc(50% - var(--half-gutter));
}

.image-block--portrait .image-block__caption {
    float: left;
    width: 50%;
    padding-left: var(--gutter);
}

@media (--small-down) {
    .image-block--portrait .image-block__image {
        float: none;
        width: auto;
        max-width: 100%;
    }
    .image-block--portrait .image-block__caption {
        float: none;
        width: auto;
        padding-left: 0;
    }
}

/**
 * @title Fullwidth
 * @id components/image-block/fullwidth
 * @markup ../../styleguide/image-block--fullwidth.html
 * @width 12
 */
.image-block--fullwidth .image-block__caption {
    width: calc(100% / 12 * 8);
}

@media (--medium-down) {
    .image-block--fullwidth .image-block__caption {
        width: auto;
    }
}
