/**
 * @title Text input
 * @id components/text-input
 * @markup ../../styleguide/text-input.html
 * @width 8
 */
.text-input {
    border: 2px solid var(--color-turquoise);
    padding: 10px;
    font-size: 1rem;
    font-family: inherit;
    width: 100%;
}

.text-input:focus {
    border-color: var(--color-black);
}

/**
 * @title Invalid
 * @id components/text-input/invalid
 * @markup ../../styleguide/text-input--invalid.html
 * @width 8
 */
.text-input--invalid {
    outline: 2px solid var(--color-red);
}

/**
 * @title Textarea
 * @id components/text-input/textarea
 * @markup ../../styleguide/text-input--textarea.html
 * @width 8
 */
