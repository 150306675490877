/**
 * @title Page link list
 * @id components/page-link-list
 * @markup ../../styleguide/page-link-list.html
 * @width 8
 */
.page-link-list {
    padding: 0;
    margin: 0 -var(--half-gutter);
    font-size: 0;
}

.page-link-list__item {
    font-size: 1rem;
    list-style: none;
    padding: 0 var(--half-gutter);
    margin: 0;
    display: inline-block;
    vertical-align: top;
    width: calc(100% / 12 * 6);
}

@media (--small-down) {
    .page-link-list__item {
        width: 100%;
    }
}
