/**
 * @title Page link
 * @id components/page-link
 * @markup ../../styleguide/page-link.html
 * @width 12
 */
.page-link {
    display: block;
    background: color(var(--color-yellow) alpha(0.8));
    padding: 10px var(--breakout);
    margin-bottom: 20px;
    border-radius: 4px;
    font-size: 1.2rem;
    text-decoration: none;
    transition: background 0.2s ease-in-out;
}

.page-link:hover,
.page-link:focus {
    background: var(--color-turquoise);
}

@media (--xlarge-down) {
    .page-link {
        padding: 10px;
    }
}

/**
 * @title Small
 * @id components/page-link/small
 * @markup ../../styleguide/page-link--small.html
 * @width 6
 */
.page-link--small {
    padding: 10px;
    font-size: 1rem;
    text-align: center;
}
