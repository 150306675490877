/**
 * @title Professional
 * @id components/professional
 * @markup ../../styleguide/professional.html
 * @width 8
 */
/**
 * @title No toggle
 * @id components/professional/no-toggle
 * @markup ../../styleguide/professional--no-toggle.html
 * @width 8
 */
.professional {
    margin: 40px 0;
    border: 2px solid var(--color-yellow);
    border-radius: 4px;
    padding: 20px 25px 0px;
}

.professional__header {
    margin-bottom: 20px;
}

.professional__name {
    margin: 0;
    font-family: "Calibre", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 1.4rem;
    line-height: 1.2;
}

.professional__title {
    font-style: italic;
    margin-bottom: 20px;
}

.professional__photo {
    float: right;
    margin: 0 0 20px 20px;
}

.professional__photo-image {
    border-radius: 50%;
    max-width: 220px;
}

.professional__company {
    border-top: 2px solid var(--color-yellow);
    padding-top: 20px;
    clear: both;
    margin-bottom: 20px;
}

.professional__company::after {
    content: "";
    display: block;
    clear: right;
}

.professional__logo {
    margin: 20px 0 20px 20px;
    float: right;
}

.professional__contact {
    margin: 0;
}

.professional__contact-header {
    font-size: 1rem;
    font-weight: bold;
    padding-right: var(--half-gutter);
}

.professional__section {
    margin-bottom: 20px;
}

.professional__subheader {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
}

@media (--medium-down) {
    .professional__photo,
    .professional__logo {
        float: none;
        margin-left: 0;
    }
}
