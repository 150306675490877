/**
 * @title Fact box
 * @id components/fact-box
 * @markup ../../styleguide/fact-box.html
 * @width 4
 */
.fact-box {
    color: var(--color-black);
    background: var(--color-white);
    border: 2px solid var(--color-yellow);
    border-radius: 4px;
    padding: 20px 20px 40px;
}

.fact-box__heading {
    margin-top: 0;
    margin-bottom: 1rem;
}

.fact-box__list {
    margin: 0;
    padding-left: 1rem;
}

.fact-box__list-item {
    list-style: none;
    position: relative;
}

.fact-box__list-item::before {
    content: "• ";
    color: var(--color-yellow);
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -1rem;
    font-size: 1.4rem;
    line-height: 1;
}
