/**
 * @title Site header
 * @id components/site-header
 * @markup ../../styleguide/site-header.html
 * @width 12
 */
.site-header {
    padding: var(--half-gutter) 0;
    margin-bottom: 3rem;
    position: relative;
}

.site-header__logo {
    display: inline-block;
    text-decoration: none;
    max-width: 10rem;
    hyphens: none;
    font-size: 0.9rem;
    line-height: 1;
}

.site-header__logo::before {
    content: inline("images/parkinson-logo.svg");
    float: left;
    width: 44px;
    height: 44px;
    margin-right: 6px;
}
