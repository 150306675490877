.l-wrapper {
    max-width: var(--grid-width);
    margin: 0 30% 0 auto;
    padding: 0 calc(var(--half-gutter) + 6px);
}

@supports (display: flex) {
    .l-wrapper--beside-menu {
        margin-right: 0;
    }
}

@media (--large-down) {
    .l-wrapper {
        margin-right: auto;
    }

    .l-wrapper--beside-menu {
        width: auto;
    }
}
