@media print {
    * {
        color: black !important;
        background: white !important;
    }

    .l-wrapper {
        max-width: none;
        margin: 0;
    }

    .l-above-footer__menu {
        display: none;
    }

    .l-above-footer__main-container {
        width: 100%;
    }

    .site-footer__social-header,
    .site-footer__social-list,
    .site-footer__cookie-info {
        display: none;
    }
}
