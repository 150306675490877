/**
 * @title Breadcrumb
 * @id components/breadcrumb
 * @markup ../../styleguide/breadcrumb.html
 * @width 12
 */
.breadcrumb {
    font-weight: normal;
}

.breadcrumb__heading {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
}

.breadcrumb__list {
    padding: 0;
    margin: 0 0 10px;
}

.breadcrumb__item {
    list-style: none;
    display: inline-block;
}

.breadcrumb__item:nth-child(n+2)::before {
    content: "/";
}
