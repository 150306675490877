/**
 * @title About us
 * @id components/about-us
 * @markup ../../styleguide/about-us.html
 * @width 8
 */
.about-us {
    margin: 0 -var(--half-gutter) 2rem;
}

.about-us::after {
    content: "";
    display: block;
    clear: left;
}

.about-us__heading,
.about-us__facebook {
    padding: 0 var(--half-gutter);
}

.about-us__section {
    float: left;
    width: 50%;
    padding: 0 var(--half-gutter);
}

.about-us__subheading {
    font-size: 1rem;
    margin: 0;
}

.about-us__item {
    margin: 0;
}

@media (--medium-down) {
    .about-us__section {
        float: none;
        width: auto;
        margin-top: 1rem;
    }
}
