.l-search-section {
    margin: 2rem 0;
}

.l-search-section__heading {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
}
