/**
 * @title Toggle filter
 * @id components/toggle-filter
 * @markup ../../styleguide/toggle-filter.html
 * @width 12
 */
.toggle-filter {
    font-size: 0;
    margin: 3rem 0 2rem;
}

.toggle-filter__item {
    font-size: 1rem;
    position: relative;
    cursor: pointer;
    margin-right: var(--gutter);
}

.toggle-filter__radio {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
}

.toggle-filter__text {
    display: inline-block;
    background: var(--color-lightturquoise);
    padding: 0.25rem var(--gutter) 0.5rem;
    margin-bottom: 1rem;
}

.toggle-filter__radio:checked + .toggle-filter__text {
    background-color: transparent;
    outline: 2px solid var(--color-black);
}

.toggle-filter__radio:focus + .toggle-filter__text {
    background-color: var(--color-yellow);
}
