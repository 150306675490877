/**
 * @title Contact block
 * @id components/contact-block
 * @markup ../../styleguide/contact-block.html
 * @width 8
 */
.contact-block {
    background-color: var(--color-lightturquoise);
    background-image: inline("images/heart.svg");
    background-repeat: no-repeat;
    background-position: var(--breakout) 1rem;
    background-size: 30px 27px;
    padding: 1rem 1rem 1px calc(var(--breakout) + 40px);
    margin: 3rem 0;
}

.contact-block__heading {
    margin-top: 0;
}

@media (--xlarge-down) {
    .contact-block {
        background-position: var(--half-gutter) 1rem;
        padding-left: calc(var(--half-gutter) + 40px);
    }
}
