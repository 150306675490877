/**
 * @title Cart summary
 * @id components/cart-summary
 * @markup ../../styleguide/cart-summary.html
 * @width 12
 */
.cart-summary {
    background: var(--color-lightturquoise);
    padding: 1rem calc(var(--breakout) - var(--half-gutter)) 0;
}

.cart-summary::after {
    content: "";
    display: block;
    clear: left;
}

.cart-summary__section {
    float: left;
    width: calc(100% / 12 * 4);
    padding: 0 var(--half-gutter);
}

.cart-summary__heading {
    font-size: 1rem;
    margin: 0;
}

.cart-summary__entries {
    padding: 0;
    margin: 0 0 1rem;
}

.cart-summary__entry {
    list-style: none;
}

.cart-summary__sum {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
}

.cart-summary__checkout {
    margin-bottom: 0;
}

@media (--xlarge-down) {
    .cart-summary {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (--medium-down) {
    .cart-summary__section {
        width: calc(100% / 12 * 6);
    }
}

@media (--small-down) {
    .cart-summary__section {
        width: calc(100% / 12 * 12);
    }
}
