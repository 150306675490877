/**
 * @title Person filter
 * @id components/person-filter
 * @markup ../../styleguide/person-filter.html
 * @width 12
 */
.person-filter {
    padding-bottom: 10px;
    border-bottom: 2px solid var(--color-turquoise);
    margin-bottom: 10px;
}

.person-filter__select-wrapper {
    margin: 20px 0;
}

.person-filter__help-toggler {
    background: var(--color-yellow);
    border: none;
    border-radius: 50%;
    padding: 0.1rem;
    font-size: 0.8rem;
    line-height: 1;
    min-width: 1rem;
    vertical-align: 0.1rem;
}

.person-filter__help-toggler:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-turquoise);
}

.person-filter__help {
    margin-left: 1.25rem;
}
