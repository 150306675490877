/**
 * @title Form block
 * @id components/form-block
 * @markup ../../styleguide/form-block.html
 * @width 12
 */
.form-block {
    border: 2px solid var(--color-turquoise);
    padding: 1rem calc(var(--breakout) - 2px);
    margin-bottom: 3rem;
}

.form-block__heading {
    margin-top: 0;
}

.form-block__text {
    max-width: 30rem;
}

.form-block__fieldset {
    margin: 2rem 0;
    padding: 0;
    border-style: solid none none;
    border-width: 2px;
    border-color: var(--color-turquoise);
}

.form-block__fieldset .form-block__fieldset {
    border-top-style: none;
}

.form-block__fieldset--hidden {
    margin: 0;
    border: none;
}

.form-block__legend {
    padding-left: 0;
    padding-right: 0.5rem;
    font-weight: bold;
}

.form-block__fieldset--hidden > .form-block__legend {
    display: none;
}

.form-block__pair {
    display: flex;
}

.form-block__field {
    margin: 1rem 0;
}

.form-block__pair .form-block__field {
    margin-right: 1rem;
}

.form-block__radios,
.form-block__checkbox {
    margin: 1rem 0 1rem -var(--half-gutter);
}

.form-block__radios {
    border: none;
    padding: 0;
}

.form-block__radio-legend {
    font-weight: bold;
}

.form-block__errors {
    margin-bottom: 0;
    color: var(--color-red);
}

.form-block__label {
    display: block;
    font-weight: bold;
}

.form-block__control {
    display: inline-block;
    margin-left: -var(--half-gutter);
    width: 100%;
    max-width: 15rem;
}

.form-block__appendage {
    padding-left: 1rem;
}

.form-block__control--short {
    width: 6rem;
}

.form-block__postfix {
    display: inline-block;
    margin-left: var(--gutter);
}

.form-block__submit {
    margin-left: -var(--half-gutter);
}

@media (--xlarge-down) {
    .form-block {
        padding: 1rem calc(var(--half-gutter) - 2px);
    }

    .form-block__control,
    .form-block__radios,
    .form-block__checkbox,
    .form-block__submit {
        margin-left: 0;
    }
}

@media (--small-down) {
    .form-block__pair {
        display: block;
    }

    .form-block__pair .form-block__field {
        margin-right: 0;
    }
}
