/**
 * @title Quote
 * @id components/quote
 * @markup ../../styleguide/quote.html
 * @width 8
 */
.quote {
    margin: 0;
    padding-left: 2rem;
    position: relative;
}

.quote::before {
    content: "“";
    font-size: 2.5rem;
    line-height: 1;
    color: var(--color-turquoise);
    position: absolute;
    top: -0.2rem;
    left: 0;
}

.quote__text {
    margin: 0 0 20px;
    font-size: 1.2rem;
    line-height: 1.2;
}

.quote__citation {
    color: var(--color-darkgray);
}

.quote__citation::before {
    content: "– ";
}

/**
 * @title Standalone
 * @id components/quote/standalone
 * @markup ../../styleguide/quote--standalone.html
 * @width 8
 */
.quote--standalone {
    margin-bottom: 2rem;
}
