/* Flexbox fallback */
.l-above-footer::after {
    content: "";
    display: block;
    clear: both; /* menu and main with IE workaround */
}

.l-above-footer__menu-toggle {
    display: none;
}

.l-above-footer__menu {
    width: 25%;
    padding-left: 2%;
    min-width: 300px;
    /* Flexbox fallback */
    float: right;
    border-left: 1px solid var(--color-gray);
    margin-top: 100px;
    margin-bottom: 100px;
}

.l-above-footer__menu[hidden] {
    display: block;
}

.l-above-footer__header {
    margin-left: -50px;
}

.l-above-footer__main {
    margin-bottom: 60px;
    /* Workaround for IE11 menu clear issue
     * https://github.com/wja-no/parkinson/issues/18
     */
    float: left;
    width: 100%;
}

@supports (display: flex) {
    .l-above-footer {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .l-above-footer__menu {
        flex: none;
    }

    .l-above-footer__main-container {
        width: 70%;
    }
}

@media (--xlarge-down) {
    .l-above-footer__header {
        margin-left: 0;
    }
}

@media (max-width: 1320px) { /* width of main column plus menu */
    .l-above-footer__menu {
        padding-left: 0;
    }

    @supports (display: flex) {
        .l-above-footer__main-container {
            width: 100%;
        }
    }
}

@media (--large-down) {
    .l-above-footer {
        display: block;
    }

    .l-above-footer__menu-toggle {
        display: block;
        float: right;
        margin-right: var(--gutter);
        position: relative;
        z-index: 1; /* Paint above main header */
    }

    .l-above-footer__menu {
        width: auto;
        float: none;
        margin-right: 0;
        clear: right;
    }

    .l-above-footer__menu[hidden] {
        display: none;
    }
}

@media (--small-down) {
    .l-above-footer__menu-toggle {
        float: none;
        margin-right: 0;
    }
}
