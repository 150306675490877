/**
 * @title Social link
 * @id components/social-link
 * @markup ../../styleguide/social-link.html
 * @width 8
 */
.social-link {
    display: block;
    padding: 0.2rem 2rem 20px 2.4rem;
    background: top left no-repeat;
    background-size: 2rem 2rem;
}

.social-link--facebook {
    background-image: inline("images/fb.svg");
}

.social-link--twitter {
    background-image: inline("images/twitter.svg");
}

.social-link--instagram {
    background-image: inline("images/insta.svg");
}

.social-link--youtube {
    background-image: inline("images/youtube.svg");
}

.social-link--vimeo {
    background-image: inline("images/vimeo.svg");
}

.social-link--linkedin {
    background-image: inline("images/linkedin.svg");
}

.social-link:focus {
    color: var(--color-black);
}
