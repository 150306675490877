@font-face {
    font-family: "Calibre";
    font-style: normal;
    font-weight: normal;
    src: url("/resources/files/CalibreWeb-Regular.woff") format("woff");
}

@font-face {
    font-family: "Calibre";
    font-style: italic;
    font-weight: normal;
    src: url("/resources/files/CalibreWeb-RegularItalic.woff") format("woff");
}

@font-face {
    font-family: "Calibre";
    font-style: normal;
    font-weight: 500;
    src: url("/resources/files/CalibreWeb-Medium.woff") format("woff");
}

@font-face {
    font-family: "Calibre";
    font-style: italic;
    font-weight: 500;
    src: url("/resources/files/CalibreWeb-MediumItalic.woff") format("woff");
}

@font-face {
    font-family: "Calibre";
    font-style: normal;
    font-weight: bold;
    src: url("/resources/files/CalibreWeb-Bold.woff") format("woff");
}

@font-face {
    font-family: "Calibre";
    font-style: italic;
    font-weight: bold;
    src: url("/resources/files/CalibreWeb-BoldItalic.woff") format("woff");
}
