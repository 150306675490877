/**
 * @title Body text
 * @id components/bodytext
 * @markup ../../styleguide/body-text.html
 * @width 8
 */

/* Duplicating the .ul component since Redactor contents don't have the
 * requisite class and it's not trivial to set
 */
.body-text ol > li,
.body-text ul > li {
    list-style: none;
    position: relative;
}

.body-text ul > li::before {
    content: "• ";
    color: var(--color-yellow);
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -1rem;
    font-size: 1.4rem;
    line-height: 1;
}

.body-text ol {
    counter-reset: list;
}

.body-text ol > li::before {
    counter-increment: list;
    content: counter(list);
    font-weight: bold;
    position: absolute;
    top: 0;
    left: -1em;
    color: var(--color-turquoise);
}
