/**
 * @title Article header
 * @id components/article-header
 * @markup ../../styleguide/article-header.html
 * @width 12
 */
.article-header {
    margin-bottom: 3rem;
}

.article-header__heading {
    margin-bottom: 2rem;
}

.article-header--front .article-header__heading {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
}

.article-header__lead {
    font-size: 1.2rem;
    line-height: 1.3;
    margin: 0 0 2rem;
    width: calc(100% / 12 * 10);
}

@media (--medium-down) {
    .article-header__lead {
        width: 100%;
    }
}

/**
 * @title With image
 * @id components/article-header/with-image
 * @markup ../../styleguide/article-header--with-image.html
 */
.article-header__figure {
    margin: 0 0 2rem;
}

.article-header__image {
    max-width: 100%;
}
