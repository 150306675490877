/**
 * @title Search form
 * @id components/search-form
 * @markup ../../styleguide/search-form.html
 * @width 12
 */
.search-form {
    margin: 1rem 0;
}

.search-form__heading {
    display: inline;
    font-weight: normal;
    margin: 0;
}

.search-form__input {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-weight: bold;
    padding: 0;
    background: none;
    border: none;
    border-width: 2px;
    border-color: var(--color-black);
    border-style: none none solid;
    width: calc(100% / 12 * 5);
}

.search-form__edit,
.search-form__search {
    background: none;
    border-width: 3px;
    border-color: transparent;
    border-style: none none solid;
    padding: 0.2rem;
    vertical-align: bottom;
}

.search-form__edit:focus,
.search-form__search:focus {
    border-bottom-color: var(--color-turquoise);
}

.search-form__edit:active,
.search-form__search:active {
    padding-bottom: 0.1rem;
}

.search-form__edit-icon {
    width: 1.4rem;
    height: calc(1.4rem / 23 * 27);
    transform: rotate(90deg);
}

.search-form__search-icon {
    width: 1.4rem;
    height: calc(1.4rem / 17 * 16);
}

@media (--small-down) {
    .search-form {
        display: flex;
    }

    .search-form__input {
        width: 100%;
    }

    .search-form__label {
        display: block;
    }
}
