/**
 * @title Filter sentence
 * @id components/filter-sentence
 * @markup ../../styleguide/filter-sentence.html
 * @width 12
 */
.filter-sentence {
    margin: 2rem 0 1.5rem;
}

.filter-sentence__label {
    margin-right: 0.5rem;
}

.filter-sentence__control {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}
