.stardoc__category {
  border: 2px solid var(--color-yellow);
  padding: 20px;
}

.stardoc__heading {
  border: 2px solid var(--color-darkturquoise);
  padding: 10px 20px;
}

.stardoc__subheading {
  border: 1px solid var(--color-turquoise);
  padding: 5px 20px;
}

.stardoc__description {
  font-weight: normal;
}

.stardoc__example-wrapper {
  margin: 0 -var(--half-gutter);
}

.stardoc__example {
  padding: 0 var(--half-gutter);
}

.stardoc__example--fullwidth {
  padding: 0;
}

.stardoc__example--12 { width: calc((100% / 12) * 12); }
.stardoc__example--11 { width: calc((100% / 12) * 11); }
.stardoc__example--10 { width: calc((100% / 12) * 10); }
.stardoc__example--9 { width: calc((100% / 12) * 9); }
.stardoc__example--8 { width: calc((100% / 12) * 8); }
.stardoc__example--7 { width: calc((100% / 12) * 7); }
.stardoc__example--6 { width: calc((100% / 12) * 6); }
.stardoc__example--5 { width: calc((100% / 12) * 5); }
.stardoc__example--4 { width: calc((100% / 12) * 4); }
.stardoc__example--3 { width: calc((100% / 12) * 3); }
.stardoc__example--2 { width: calc((100% / 12) * 2); }
.stardoc__example--1 { width: calc((100% / 12) * 1); }

.stardoc__markup {
  font-size: 16px;
}

.stardoc__markup.is-hidden {
  display: none;
}

.stardoc__grid {
  bottom: 0;
  left: 6px;
  margin: 0 30% 0 auto;
  max-width: 1020px;
  opacity: 0.5;
  pointer-events: none;
  position: fixed;
  right: 6px;
  top: 0;
  z-index: 1;
}

.stardoc__grid.is-hidden {
  display: none;
}

.stardoc__grid__column {
  float: left;
  width: calc(100% / 12);
  height: 100vh;
  border-color: transparent;
  border-width: 10px;
  border-style: none solid;
  box-shadow: inset 1px 0 0 0 blue, inset -1px 0 0 0 blue;
}

.stardoc__build-tag {
  background: lightblue;
  padding: 2px;
  position: fixed;
  bottom: 0;
  right: 0;
}

@media (--large-down) {
  .stardoc__grid {
    margin-right: auto;
  }
}
