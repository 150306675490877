/**
 * @title Main menu
 * @id components/main-menu
 * @markup ../../styleguide/main-menu.html
 * @width 5
 */
.main-menu {
    padding: 20px;
    height: 100%;
    position: relative;
    z-index: 0;
}

.main-menu__content {
    width: 300px;
    max-width: 100%;
}

.main-menu__content.is-sticky-to-top {
    position: fixed;
    top: 0;
    max-width: calc(24% - 2 * 20px);
}

.main-menu__content.is-sticky-to-bottom {
    position: absolute;
    bottom: 0;
}

.main-menu__heading {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
}

.main-menu__search-section {
    padding: 0.5rem 0.1rem;
    border-bottom: 1px solid var(--color-gray);
    position: relative;
    z-index: 0;
}

.main-menu__search-heading {
    margin-top: 0;
    font-size: 1rem;
    font-weight: normal;
    position: absolute;
    top: 0.5rem;
    left: 0.1rem;
    z-index: -1;
}

.main-menu__search-field {
    font-size: 1rem;
    font-family: inherit;
    width: 100%;
    background: transparent;
    border: none;
    padding: 0 1rem 0 0;
}

.main-menu__search-field:focus,
.main-menu__search-field:valid {
    background: var(--color-white);
}

.main-menu__search-button {
    background: transparent;
    border: none;
    padding: 0;
    position: absolute;
    top: 0.5rem;
    right: 0;
}

.main-menu__search-button:focus {
    background: var(--color-yellow);
}

.main-menu__search-icon {
    width: 0.8rem;
    height: calc(0.8rem / 17 * 16);
}

.main-menu__section {
    padding: 0.5rem 0.1rem;
}

.main-menu__section-heading {
    font-size: 1rem;
    margin: 0.5rem 0 0;
}

.main-menu__article-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0.9rem;
}

.main-menu__section-link,
.main-menu__article-link {
    text-decoration: none;
    line-height: 1.2;
}

.main-menu__section-link:hover,
.main-menu__article-link:hover {
    text-decoration: underline;
}

.main-menu__article-link--current {
    font-weight: bold;
}

@media (--large-down) {
    .main-menu__content {
        width: 100%;
    }

    .main-menu__content.is-sticky-to-top,
    .main-menu__content.is-sticky-to-bottom {
        position: static;
        max-width: none;
    }
}
