/**
 * @title Peer
 * @id components/peer
 * @markup ../../styleguide/peer.html
 * @width 8
 */
.peer {
    margin: 40px 0;
    border: 2px solid var(--color-yellow);
    border-radius: 4px;
    padding: 30px 25px 20px;
}

.peer::after {
    content: "";
    display: block;
    clear: right;
}

.peer__figure {
    float: right;
    width: 200px;
    margin: 0 0 20px 20px;
}

.peer__image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.peer__name {
    margin: 0;
}

.peer__quote {
    border-top: 2px solid var(--color-yellow);
    padding-top: 20px;
    margin: 20px 0;
    clear: right;
}

@media (--medium-down) {
    .peer__figure {
        float: none;
        margin-left: 0;
    }
}

@media (--small-down) {
    .peer {
        padding-left: var(--gutter);
    }

    .peer__quote {
        margin-top: 1rem;
    }
}
