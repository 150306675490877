/**
 * @title Product
 * @id components/product
 * @markup ../../styleguide/product.html
 * @width 8
 */
.product {
    margin-bottom: 3rem;
}

.product__figure {
    margin: 0;
}

.product__image {
    max-width: 100%;
}

.product__price {
    font-size: 1.4rem;
    font-weight: bold;
    margin: 1rem 0 0;
}
