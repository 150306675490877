/**
 * @title Author
 * @id components/author
 * @markup ../../styleguide/author.html
 * @width 8
 */
.author {
    margin: 3rem 0 2rem;
    border-width: 2px;
    border-color: var(--color-yellow);
    border-style: solid none;
    padding: 0.5rem 0 1rem;
}

.author::after {
    content: "";
    display: block;
    clear: left;
}

.author__heading {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    margin: 0 0 0.5rem;
}

.author__figure {
    float: left;
    width: 100px;
    margin: 0;
}

.author__image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.author__info {
    padding-left: 120px;
}

.author__name {
    font-size: 1rem;
    margin: 1rem 0 0;
}

.author__title {
    margin: 0;
}
