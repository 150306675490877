/**
 * @title Site footer
 * @id components/site-footer
 * @markup ../../styleguide/site-footer.html
 * @width fullwidth
 */
.site-footer {
    padding: 40px 0 60px;
    background: var(--color-black);
    color: var(--color-darkoffwhite);
    /* don't let sticky menu overlap */
    position: relative;
    z-index: 0;
}

.site-footer__social-header {
    margin: 0;
}

.site-footer__social-list {
    margin: 20px 0;
    padding: 0;
}

.site-footer__social-item {
    list-style: none;
    display: inline-block;
}

.site-footer__email-link:focus {
    color: var(--color-black);
}

.site-footer__contact-info-header {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
}

.site-footer__contact-info {
    margin: 0 -var(--half-gutter);
}

.site-footer__contact-info:after {
    content: "";
    display: block;
    clear: left;
}

.site-footer__physical-address,
.site-footer__remote-contact {
    float: left;
    width: calc((100% / 12) * 6);
    padding: 0 var(--half-gutter);
    margin-bottom: 70px;
}

.site-footer__phone {
    margin-bottom: 0;
}

@media (--medium-down) {
    .site-footer__physical-address,
    .site-footer__remote-contact {
        width: 100%;
    }
}
