/**
 * @title Button
 * @id components/button
 * @markup ../../styleguide/button.html
 * @width 8
 */

/**
 * @title "Fake"/link
 * @id components/button/link
 * @markup ../../styleguide/button--link.html
 * @width 8
 */
.button {
    font-family: inherit;
    font-size: 1rem;
    line-height: inherit;
    font-weight: inherit;
    text-decoration: none;
    color: inherit;
    background: var(--color-yellow);
    border: none;
    display: inline-block;
    padding: 10px 20px;
    min-width: 200px;
    margin: 20px 0;
    transition: all 0.1s ease-in-out;
    text-align: center;
    /* transition may get jumpy without the following (seen in Firefox without the webfont) */
    transform: translateY(0);
}

.button:hover,
.button:focus {
    outline: none;
    box-shadow: 0 3px 0 color(var(--color-darkturquoise) alpha(0.5));
    transform: translateY(-3px);
}

.button:hover {
    cursor: pointer;
}

.button:active {
    transform: translateY(0);
    box-shadow: none;
    opacity: 1; /* override a:active */
}

@media (--small-down) {
    .button {
        min-width: 0;
    }
}

.button--jump-to-main {
    position: absolute;
    margin: 0;
    top: -200px;
    left: 0;
}

.button--jump-to-main:focus {
    top: 0px;
}

/**
 * @title Secondary
 * @id components/button/secondary
 * @markup ../../styleguide/button--secondary.html
 * @width 8
 */
.button--secondary {
    background: var(--color-turquoise);
    min-width: 0;
}

.button--secondary:hover,
.button--secondary:focus {
    box-shadow: 0 3px 0 var(--color-yellow);
}

/**
 * @title Submit
 * @id components/button/submit
 * @markup ../../styleguide/button--submit.html
 * @width 8
 */
.button--submit {
    padding-left: var(--half-gutter);
    text-align: left;
}

/**
 * @title Disabled
 * @id components/button/disabled
 * @markup ../../styleguide/button--disabled.html
 * @width 8
 */
.button[disabled],
.button[disabled]:active {
    opacity: 0.5;
}

.button[disabled]:hover {
    transform: translateY(0);
    box-shadow: none;
    cursor: auto;
}

/**
 * @title Toggle button
 * @id components/button/toggle
 * @markup ../../styleguide/button--toggle.html
 * @width 8
 */
/**
 * @title Toggle button, expanded
 * @id components/button/toggle-expanded
 * @markup ../../styleguide/button--toggle-expanded.html
 * @width 8
 */
.button--toggle {
    background: none;
    padding: 0;
    text-align: left;
}

.button--toggle::before {
    content: "";
    float: left;
    background: inline("images/arrow-down.svg") center 60% no-repeat var(--color-yellow);
    background-size: 40% 30%;
    width: 34px;
    height: 34px;
    margin-right: var(--half-gutter);
    border-radius: 50%;
    transition: transform 500ms cubic-bezier(0.19, 1, 0.22, 1);
}

.button--toggle:hover,
.button--toggle:focus {
    box-shadow: none;
    transform: none;
    text-decoration: underline;
}

.button--toggle.is-expanded::before {
    transform: rotate(-180deg);
}

/**
 * @title Hidden state
 * @id components/button/hidden
 * @markup ../../styleguide/button--hidden.html
 * @width 8
 */
.button--hidden {
    display: none;
}

@media (--small-down) {
    .button--toggle::before {
        width: 28px;
        height: 28px;
    }
}
