/**
 * @title Branch list
 * @id components/branch-list
 * @markup ../../styleguide/branch-list.html
 * @width 12
 */
.branch-list {
    font-size: 0;
    margin: 0 -var(--half-gutter);
}

.branch-list__region {
    font-size: 1rem;
    display: inline-block;
    width: calc(100% / 12 * 4);
    padding: 0 var(--half-gutter);
    vertical-align: top;
}

.branch-list__region[hidden] {
    display: none;
}

.branch-list__heading {
    margin-bottom: 0;
}

@media (--medium-down) {
    .branch-list__region {
        width: calc(100% / 12 * 6);
    }
}

@media (--small-down) {
    .branch-list__region {
        width: calc(100% / 12 * 12);
    }
}
